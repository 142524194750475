import React, { Component } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"
import styled, { css } from "styled-components"
import { Link, animateScroll as scroll } from "react-scroll";
import axios from "axios"

import { Row, Column } from "../components/bulma-components"
import Layout from "../components/layout";
import SEO from "../components/seo";

const style = {
  green: "#649F0E",
  orange: "#E88E2B",
  purple: "#9B4C9D",
  grey: "#fdfdfd",
  darkgrey: "#f1f1f1",
}

const centered = css`
  @media(min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const SectionHeader = styled.h1`
  color: ${props => style[props.varient]};
  text-transform: uppercase;
  font-size: 3.2rem;
  font-weight: 600;
  padding-top: 2rem;
`

const ImageColumn = styled(Column)`
`

const SmallHeader = styled(Link)`
  text-transform: uppercase;
  font-size: 1.4rem;
  display: block;
  font-weight: 600;
  border-top: 5px solid ${props => style[props.varient]};
  color: ${props => style[props.varient]};
  letter-spacing: -0.5px;
  &:hover {
    color: ${props => style[props.varient]};
  }
`

const P = styled.p`
  font-size: ${props => props.size === "large" ? "1.3rem" : "0.875rem"};
  color: #555555;
  & a {
    color: #555555;
  }
`

const SectionSubHeader = styled.h2`
  font-size: 1.3rem;
  font-weight: bold;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  color: #555555;
`

const StyledColumn = styled(Column)`
  padding-top: ${props => props.padding}rem;
  padding-left: ${props=> props.paddingLeft && `${props.paddingLeft}rem`};
`

const Section = styled.div`
  background: ${props => style[props.varient]};
  min-height: 100vh;
  ${props => props.centered && centered}
  @media(max-width: 1024px) {
    & .column {
      padding: 2rem
    }
  }
`

const HomeSection = styled(Section)`
  min-height: calc(100vh - 93px);
`

const Seperator = styled.hr`
  background: ${props => style[props.varient]};
  width: 100%;
  height: 4px;
  margin: 0px;
`

const Form = styled.form`

`
const Input = styled.input`
  display: block;
  padding: 0.7rem 0rem;
  width: 100%;
  padding-left: 0.5rem;
`

const Textarea = styled.textarea`
  display: block;
  padding: 0.7rem 0rem;
  width: 100%;
  padding-left: 0.5rem;
`

const Submit = styled.button`
  display: block;
  margin-top: 1rem;
  font-size: 0.85rem;
  padding: 0.5rem 1.5rem;
  background: ${style.purple};
  color: white;
  border: none;
  font-weight: bold;
`

const Label = styled.label`
  font-size: 0.8rem;
  padding-bottom: 0.4rem;
  padding-top: 1rem;
  display: block;
`

class IndexPage extends Component {
  state = {
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
    gotcha: "",
    offset: -85
  }

  componentDidMount = () => {
    if(window.innerWidth < 1024) {
      this.setState({offset: 0})
    }
  }

  handleChange = event => {
    const name = event.target.name
    const value = event.target.value

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    axios
      .post("https://formspree.io/myqaedjm", {
        name: this.state.name,
        company: this.state.company,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message,
        _gotcha: this.state.gotcha,
        _cc: "ella@bycroftconsulting.com",
        _replyto: this.state.email,
      })
      .then(() => {
        this.setState({
          name: "",
          company: "",
          email: "",
          phone: "",
          message: "",
          gotcha: "",
        })
      })
  }

  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO title="Home" />
        <HomeSection varient="grey" centered="true" name="home">
          <Row className="is-centered">
            <ImageColumn className="is-half-widescreen is-two-thirds-desktop is-full-mobile is-centered is-hidden-mobile">
              <Img fluid={data.largeLogo.childImageSharp.fluid} alt="Bycroft Consulting" />
            </ImageColumn>
          </Row>
          <Row className="is-centered">
            <Column className="is-half-widescreen is-two-thirds-desktop is-full-mobile is-centered">
              <Row>
                <Column>
                  <SmallHeader
                    varient="green"
                    to="about"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={this.state.offset}
                  >
                    About Us
                  </SmallHeader>
                  <P>
                    <Link
                      to="about-us-intro"
                      smooth={true}
                      duration={500}
                      spy={true}
                      offset={this.state.offset}
                    >
                      Introduction
                    </Link>
                  </P>
                  <P>
                    <Link
                      to="about-us-clients"
                      smooth={true}
                      duration={500}
                      spy={true}
                      offset={this.state.offset}
                    >
                      Our Clients
                    </Link>
                  </P>
                  <P>
                    <Link
                      to="about-us-people"
                      smooth={true}
                      duration={500}
                      spy={true}
                      offset={this.state.offset}
                    >
                      Our People
                    </Link>
                  </P>
                </Column>
                <Column>
                  <SmallHeader
                    varient="orange"
                    to="services"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={this.state.offset}
                  >
                    What We Do
                  </SmallHeader>
                  <P>
                    <Link
                      to="what-we-do-strat"
                      smooth={true}
                      duration={500}
                      spy={true}
                      offset={this.state.offset}
                    >
                      Strategy
                    </Link>
                  </P>
                  <P>
                    <Link
                      to="what-we-do-market"
                      smooth={true}
                      duration={500}
                      spy={true}
                      offset={this.state.offset}
                      >
                        Marketing
                    </Link>
                  </P>
                  <P>
                    <Link
                      to="what-we-do-impl"
                      smooth={true}
                      duration={500}
                      spy={true}
                      offset={this.state.offset}
                      >
                        Implementation
                    </Link>
                  </P>
                </Column>
                <Column>
                  <SmallHeader
                    varient="purple"
                    to="contact"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={this.state.offset}
                  >
                    Contact
                  </SmallHeader>
                  <P>
                    <Link
                      to="contact"
                      smooth={true}
                      duration={500}
                      spy={true}
                      offset={this.state.offset}
                    >
                      Our Details
                    </Link>
                  </P>
                  <P><Link
                      to="contact"
                      smooth={true}
                      duration={500}
                      spy={true}
                      offset={this.state.offset}
                    >
                    Enquire Now
                  </Link>
                </P>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row className="is-centered has-text-centered">
            <StyledColumn className="is-half-widescreen is-two-thirds-desktop is-full-mobile" padding={6}>
              <P className="has-text-weight-bold" size="large">We look forward to helping your business grow</P>
            </StyledColumn>
          </Row>
          <Row className="is-centered has-text-centered">
            <StyledColumn className="is-half-widescreen is-two-thirds-desktop is-full-mobile" padding={1.25}>
              <Link to="about" spy={true} offset={this.state.offset} smooth={true}>
                <Img fixed={data.arrow.childImageSharp.fixed} alt="About Us Section"/>
              </Link>
            </StyledColumn>
          </Row>
        </HomeSection>
        <Section varient="darkgrey" name="about">
          <Seperator varient="green" />
          <Row className="is-centered">
            <Column className="is-half-widescreen is-two-thirds-desktop is-full-mobile">
              <SectionHeader varient="green">About Us</SectionHeader>
              <SectionSubHeader name="about-us-intro">Welcome to Bycroft Consulting!</SectionSubHeader>
              <P>At Bycroft Consulting we specialise in understanding our customers’ needs and working with them in both problem solving and opportunity enhancing. We do this by providing realistic and innovative business, strategic and marketing solutions usually as it relates to the international education industry. Our team is successful, experienced and our reach is global. Bycroft Consulting has a long term commitment and vision that ensures the continued improvement of student services and overall experience for international students. Our speciality is working with clients who share this vision. To date our team has been instrumental in changing for the better the face and performance of the international education industry. We develop better businesses by working with our clients to help international students to become safer, enjoy a better ‘student experience’ and we focus on ensuring that international students have a much better chance of successfully achieving their goals. Bycroft Consulting is happy to talk with anyone interested in finding out more and joining our ‘International Student Revolution’!</P>
              <SectionSubHeader name="about-us-clients">Our Clients</SectionSubHeader>
              <P>We work with a wide range of clients from small businesses and to multinational global organisations. By operating with the international student’s best interest as our first priority, and partnering with like-minded companies who aim to provide “best-in-class” student services, we can optimise the success in every aspect of our projects.</P>
              <SectionSubHeader name="about-us-people">Our Team</SectionSubHeader>
              <P>We are a small team of passionate consultants with varied backgrounds across business strategy, marketing, sales and education services. By working together we utilise each other’s individual strengths in order to deliver a quality and innovative solution for our clients.</P>
            </Column>
          </Row>
          <Row className="is-centered has-text-centered">
            <StyledColumn className="is-half-widescreen is-two-thirds-desktop is-full-mobile" padding={5}>
              <Link to="services" spy={true} offset={this.state.offset} smooth={true}>
                <Img fixed={data.arrow.childImageSharp.fixed} alt="What We Do Section"/>
              </Link>
            </StyledColumn>
          </Row>
        </Section>
        <Section varient="grey" name="services">
          <Seperator varient="orange" />
          <Row className="is-centered">
            <Column className="is-half-widescreen is-two-thirds-desktop">
              <SectionHeader varient="orange">What We Do</SectionHeader>
              <Row>
                <Column className="is-one-third-desktop">
                  <SectionSubHeader name="what-we-do-strat">Strategy</SectionSubHeader>
                  <P><i>...is a high level plan to achieve one or more goals under conditions of uncertainty. Bycroft Consulting brings innovative and effective strategic planning to groups and projects where ‘out of the box’ unique solutions and creative planning is required to achieve results. We design innovative, unique selling point solutions that help clients reach their target audience, and usually our involvement opens up additional opportunities without losing sight of project requirements and limitations. Our group has intimate knowledge of the international education sector and a passion to make a difference in this industry. This helps assure our customers that when we take a project on we are not only committed to finding the best solution for our client, but are ready to see each project through to its successful completion.</i></P>
                </Column>
                <Column className="is-one-third-desktop">
                  <SectionSubHeader name="what-we-do-market">Marketing</SectionSubHeader>
                  <P><i>...is communicating the value of a product, service or brand to customers, for the purpose of promoting or selling that product, service, or brand. Brand establishment, penetration and recognition is a key strength of the Bycroft Consulting Group. When we take a project on we often prefer to be rewarded through the success of the project as a result of our strategy and marketing plans. That way we are usually very low upfront costs with high success factor bonus – we share the risk and share the profit. We are specialists in ‘Partnering Marketing’ – a term which we created and have successfully used in the international student industry with exceptional results for our clients. Partnering Marketing is all about maximising the opportunity and use of our extensive networks to unite on a single strategy that supports cooperative and consistent messaging for the benefit of all participating partners.</i></P>
                </Column>
                <Column className="is-one-third-desktop">
                  <SectionSubHeader name="what-we-do-impl">Implementation</SectionSubHeader>
                  <P><i>...is the realisation of an application, or execution of a plan, idea, model, design, specification, standard, algorithm or policy. Usually when Bycroft Consulting is involved in the strategy and marketing for a project we also assist with implementation plans. This is critical as there is a fine line between success and failure. Our job is usually not finished until there has been a successful implementation result. If you are looking for average results we are not your team.</i></P>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row className="is-centered has-text-centered">
            <StyledColumn className="is-half-widescreen is-two-thirds-desktop is-full-mobile" padding={5}>
              <Link to="contact" spy={true} offset={this.state.offset} smooth={true}>
                <Img fixed={data.arrow.childImageSharp.fixed} alt="Contact Us Section"/>
              </Link>
            </StyledColumn>
          </Row>
        </Section>
        <Section varient="darkgrey" name="contact">
          <Seperator varient="purple" />
          <Row className="is-centered">
            <Column className="is-half-widescreen is-two-thirds-desktop">
              <SectionHeader varient="purple">Contact</SectionHeader>
              <Row>
                <Column className="is-three-fifths-desktop is-full-mobile">
                  <SectionSubHeader>Send us an enquiry</SectionSubHeader>
                  <Form onSubmit={e => this.handleSubmit(e)}>
                    <Input
                      type="text"
                      name="gotcha"
                      style={{ display: "none" }}
                      value={this.state.gotcha}
                      onChange={this.handleChange}
                    />
                    <Label htmlFor="name">Name</Label>
                    <Input className="input" type="text" name="name" value={this.state.name} onChange={this.handleChange} required/>
                    <Label htmlFor="company">Company</Label>
                    <Input className="input" type="text" name="company" value={this.state.company} onChange={this.handleChange} />
                    <Label htmlFor="email">Email</Label>
                    <Input className="input" type="text" name="email" value={this.state.email} onChange={this.handleChange} required/>
                    <Label htmlFor="phone">Phone</Label>
                    <Input className="input" type="text" name="phone" value={this.state.phone} onChange={this.handleChange} />
                    <Label htmlFor="message">Message</Label>
                    <Textarea className="textarea" rows="10" name="message" value={this.state.message} onChange={this.handleChange} />
                    <Submit>Go</Submit>
                  </Form>
                  <SectionSubHeader>We look forward to helping your business grow</SectionSubHeader>
                </Column>
                <StyledColumn className="is-two-fifths-desktop is-full-mobile" padding={4} paddingLeft={3}>
                  <P style={{paddingBottom: "1rem"}}><strong>Our Details</strong></P>
                  <P><strong>Phone</strong> 07 3870 4446</P>
                  <P><strong>Email</strong> info@bycroftconsulting.com</P>
                  <P><strong>Address</strong> Suite R01, L2, Toowong Towers, 9 Sherwood Rd, Toowong</P>
                  <P><strong>Postal</strong> PO Box 1923 Toowong BC Q 4066</P>
                  <P><strong>ABN</strong> 96 034 140 438</P>
                </StyledColumn>
              </Row>
            </Column>
          </Row>
        </Section>
      </Layout>
    );
  }
};

export const query = graphql`
  query {
    largeLogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 930) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    arrow: file(relativePath: { eq: "arrow_down.png" }) {
      childImageSharp {
        fixed(width: 40, height: 47) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`

export default IndexPage;
